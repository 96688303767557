import * as React from "react"
import {Counter, CounterProps} from "./Counter.tsx"

type HideableCounterProps=CounterProps;

export function HideableCounter(props:HideableCounterProps): React.ReactElement {
    const [isVisible, setIsVisible]=React.useState(true);
    const toggleShowCounterButton=() => {setIsVisible(!isVisible)};
    const toggleShowCounterButtonText=isVisible ? "Hide Counter" : "Show Counter";
    const spanStyle=isVisible ? {} : {display: "none"};
    return <>
        <button onClick={toggleShowCounterButton}>{toggleShowCounterButtonText}</button>
        <span style={spanStyle}>
            <Counter {...props}/>
        </span>
    </>;
}