import * as React from "react"

type CounterProps={
    init: number,
    min: number,
    max: number,
}

export function Counter(props:CounterProps): React.ReactElement {
    const [count, setCount]=React.useState(props.init);
    const incrementCount=() => {
        setCount(count==props.max ? props.max : count+1);
    }
    const decrementCount=() => {
        setCount(count==props.min ? props.min : count-1);
    }

    return <div>
        <p>The count is {count}</p>
        <button onClick={incrementCount}>(+)</button>
        <button onClick={decrementCount}>(-)</button>
    </div>;
}